'use client'

import { sendGAEvent } from '@next/third-parties/google'

const googleSendEvent = (event: string, ntmData: any, callback?: () => void) => {
    const { CookieFirst }: any = window

    if (CookieFirst) {
        if (true === CookieFirst.consent?.advertising) {
            sendGAEvent('event', event, ntmData);
        }

        if (callback) callback();
    }
}

export default googleSendEvent
